import React, { useState } from "react";

// typings
import { CheckoutLabService } from "../../../utils/interfaces/LabServices";
import { PackAndService } from "../../../utils/interfaces/Pack";
import { Locale } from "../../../utils/interfaces/Locale";

// components
import Flex from "../../Containers/Flex";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import {
  CounterWrapper,
  SAddBoxIcon,
  SAutocomplete,
  SDeleteForeverIcon,
  SIndeterminateCheckBoxIcon,
} from "../../../styles/Layout/Selectors";

interface Props {
  labServices: PackAndService[];
  setLabServices: (labService: PackAndService[]) => void;
  allLabServices: CheckoutLabService[];
  country?: Locale;
  enableFonasaSelector?: boolean;
}

const SelectLabService = (props: Props): JSX.Element => {
  const [addLabService, setAddLabService] = useState(false);
  const [addLabServiceSelect, setAddLabServiceSelect] = useState<{
    id: string;
    name: string;
    service_code: string;
    isPack: boolean;
  }>({ id: "", name: "", service_code: "", isPack: false });

  const idToService = {};
  props.allLabServices.forEach((labService) => {
    idToService[labService.id] = labService.name;
  });

  const addAmount = (id: string) => {
    props.setLabServices(
      props.labServices.map((labService) => {
        if (labService.id === id) {
          labService.amount++;
        }
        return labService;
      })
    );
  };

  const subtractAmount = (id: string) => {
    props.setLabServices(
      props.labServices.map((labService) => {
        if (labService.id === id && labService.amount > 1) {
          labService.amount--;
        }
        return labService;
      })
    );
  };

  const deleteSelected = (id: string) => {
    props.setLabServices(
      props.labServices.filter((labService) => labService.id !== id)
    );
  };

  const handleAddLabService = (newValue) => {
    if (!newValue) {
      setAddLabService(false);
      return;
    }
    // check if service is already on list
    const actualLabServices = props.labServices.map(
      (labService) => labService.id
    );
    if (actualLabServices.includes(newValue.id)) {
      setAddLabService(false);
      setAddLabServiceSelect({
        id: "",
        name: "",
        service_code: "",
        isPack: false,
      });
      return;
    }
    props.setLabServices([
      ...props.labServices,
      {
        id: newValue.id,
        amount: 1,
        fonasa: false,
        isPack: !!newValue.isPack,
      },
    ]);
    setAddLabServiceSelect({
      id: "",
      name: "",
      service_code: "",
      isPack: false,
    });
    setAddLabService(false);
  };

  const handleFonasaChange = (event, serviceId) => {
    props.setLabServices(
      props.labServices.map((labService) => {
        if (labService.id === serviceId) {
          labService["fonasa"] = event.target.checked;
        }
        return labService;
      })
    );
  };

  return (
    <Flex direction="column">
      {props.labServices.map((labService) => {
        return (
          <Flex justify="space-between" align="center" margin="0.25rem 0rem">
            <p style={{ width: "70%" }}>
              {labService.isPack ? "Pack: " : ""}
              {idToService[labService.id]}
            </p>
            {props.country !== "mx" && props.enableFonasaSelector && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <h5>Fonasa</h5>
                <Switch
                  checked={labService["fonasa"]}
                  onChange={(e) => handleFonasaChange(e, labService.id)}
                />
              </div>
            )}
            <Flex align="center">
              {!labService.isPack && (
                <CounterWrapper>
                  {labService.amount}
                  <Flex
                    direction="column"
                    align="center"
                    margin="0rem 0rem 0rem 0.5rem"
                  >
                    <SAddBoxIcon
                      fontSize="small"
                      style={{ color: "#888888" }}
                      onClick={() => {
                        addAmount(labService.id);
                      }}
                    />
                    <SIndeterminateCheckBoxIcon
                      fontSize="small"
                      style={{ color: "#888888" }}
                      onClick={() => {
                        subtractAmount(labService.id);
                      }}
                    />
                  </Flex>
                </CounterWrapper>
              )}
              <SDeleteForeverIcon
                color="secondary"
                onClick={() => {
                  deleteSelected(labService.id);
                }}
              />
            </Flex>
          </Flex>
        );
      })}

      {addLabService ? (
        <Flex justify="space-between" align="center" margin="0.25rem 0rem">
          <SAutocomplete
            options={props.allLabServices}
            getOptionLabel={(option: any) => {
              if (!option.isPack) {
                return `${option.name} (${option.service_code}) - $${option.price} - ${option.lab_name} ${option.widgets}`;
              } else {
                return `Pack: ${option.name} ${
                  option.service_code ? "(" + option.service_code + ")" : ""
                } - $${option.final_price} - ${option.services?.[0]?.lab_name} ${
                  option.widgets || []
                }`;
              }
            }}
            value={addLabServiceSelect}
            onChange={(event: any, newValue: any) => {
              setAddLabServiceSelect(newValue);
              handleAddLabService(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} helperText="Examen" variant="standard" />
            )}
          />
        </Flex>
      ) : (
        <button
          className="flex gap-x-1 items-center"
          onClick={() => {
            setAddLabService(true);
          }}
          type="button"
        >
          <SAddBoxIcon color="primary" />
          <p>Agregar servicios</p>
        </button>
      )}
    </Flex>
  );
};

export default SelectLabService;
