import React, { MutableRefObject, useEffect, useState } from "react";
import styled from "styled-components";
import patientService from "../../utils/api/v1/patientService";
import { fetchHelper } from "../../utils/api/v1/fetchHelper";
import { format } from "rut.js";
import { onlyNumbers } from "../../utils/numbers";
import SelectLabService from "../Appointments/LabServices/SelectLabService";
import CircularProgress from "@material-ui/core/CircularProgress";

//typings
import { User } from "../../utils/interfaces/User";

// components
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { Patient } from "../../utils/interfaces/AppointmentPatient";
import Flex from "../Containers/Flex";
import moment from "moment";
import { PackAndService } from "../../utils/interfaces/Pack";
import DoctorSelector from "../Appointments/DoctorSelector";
import { IDoctorData, IDoctorPayload } from "../../utils/interfaces/Funnel";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  align-items: center;

  .MuiFormControl-root {
    margin: 0.5rem 2rem;
  }
`;

const SButton = styled(Button)`
  padding: 0.5rem 2rem;
`;

interface Props {
  userData: Patient;
  setUserData: any; //(newValue: User | ((prevState: User) => User)) => void;
  includeMail?: boolean;
  prefill?: boolean;
  country?: string;
  allLabServices?: PackAndService[];
  setUserServices?: (services: PackAndService[]) => void;
  userServices?: PackAndService[];
  enableFonasaSelector?: boolean;
  showExamSelector?: boolean;
  insurances?: string[];
  doctorRef?: MutableRefObject<Array<IDoctorData | null>>;
  allDoctors?: IDoctorPayload[];
  index?: number;
}

const UserInput = (props: Props): JSX.Element => {
  const [fetchingPatient, setFetchingPatient] = useState<boolean>(false);

  const formChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.target.value;
    const attribute = event.target.id;
    props.setUserData((prevState: Patient): Patient => {
      let extraProps = {};
      if (prevState.document_type === "ci" && attribute === "document_number") {
        const ci = format(newValue);
        extraProps = {
          document_number: ci,
        };
      }
      if (attribute === "document_type" && newValue === "ci") {
        const ci = format(onlyNumbers(prevState.document_number));
        extraProps = {
          document_number: ci,
        };
      }
      return {
        ...prevState,
        [attribute]: newValue,
        ...extraProps,
      };
    });
  };

  const genderChange = (event): void => {
    props.setUserData((prevState: User): User => {
      const newState = { ...prevState };
      newState.gender = event.target.value;
      return newState;
    });
  };

  const insuranceChange = (event): void => {
    props.setUserData((prevState: Patient): Patient => {
      const newState = { ...prevState };
      newState.insurance = event.target.value;
      return newState;
    });
  };

  const prefillHandler = async () => {
    await fetchHelper(setFetchingPatient, async () => {
      const data = (
        await patientService.detailsByIdentifier(props.userData.document_number)
      ).data.data;
      delete data["id"];
      delete data["country"];
      data["date_of_birth"] = moment(data["date_of_birth"]).format(
        "YYYY-MM-DD"
      );
      props.setUserData(
        (prevState: Patient): Patient => ({ ...prevState, ...data })
      );
    });
  };

  const parseGenericId = () => {
    const parsedBirthDate = props.userData.date_of_birth?.split("-").join("");
    return `${props.userData.first_name}${props.userData.last_name}${parsedBirthDate}`.toUpperCase();
  };

  useEffect(() => {
    if (props.userData.document_type === "generic_id") {
      props.setUserData({
        ...props.userData,
        document_number: parseGenericId(),
      });
    }
  }, [
    props.userData.first_name,
    props.userData.last_name,
    props.userData.date_of_birth,
  ]);

  useEffect(() => {
    if (props.country === "mx") {
      props.setUserData({
        ...props.userData,
        document_type: "generic_id",
        document_number: parseGenericId(),
      });
    }
  }, []);

  useEffect(() => {
    let document_number = props.userData.document_number;
    if (props.userData.document_type === "generic_id") {
      document_number = parseGenericId();
    } else if (
      props.userData.document_type === "passport" &&
      props.userData.document_number === parseGenericId()
    ) {
      document_number = "";
    }
    props.setUserData({
      ...props.userData,
      document_number: document_number,
    });
  }, [props.userData.document_type]);

  return (
    <>
      {props.showExamSelector && (
        <Flex direction="column">
          <Flex
            direction="row"
            padding="1rem 2rem"
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <h4>Exámenes</h4>
          </Flex>

          {props.allLabServices?.length && props.setUserServices ? (
            <SelectLabService
              allLabServices={props.allLabServices ? props.allLabServices : []}
              setLabServices={props.setUserServices}
              labServices={props.userServices ? props.userServices : []}
              country={props.userData.country}
            />
          ) : (
            <Flex justify="center" align="center" margin="2rem">
              <CircularProgress />
            </Flex>
          )}
          <Flex
            direction="row"
            padding="1rem 2rem"
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <h4>Doctor</h4>
          </Flex>

          {props.doctorRef && props.index !== undefined ? (
            <DoctorSelector
              allDoctors={props.allDoctors}
              doctorRef={props.doctorRef}
              country={props.userData.country}
              index={props.index}
            />
          ) : (
            <Flex justify="center" align="center" margin="2rem">
              <CircularProgress />
            </Flex>
          )}
          <Flex
            direction="row"
            padding="1rem 2rem"
            style={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <h4>Datos</h4>
          </Flex>
        </Flex>
      )}

      {props.userData.country !== "mx" && (
        <Row>
          <FormControl fullWidth>
            <RadioGroup
              row={true}
              value={props.userData.document_type}
              onChange={formChange}
              defaultValue="ci"
            >
              <FormControlLabel
                value="ci"
                control={<Radio id="document_type" />}
                label="RUT"
              />
              <FormControlLabel
                value="passport"
                control={<Radio id="document_type" />}
                label="Pasaporte"
              />
            </RadioGroup>
          </FormControl>
          {(props.userData.document_type === "ci" ||
            props.userData.document_type === undefined) && (
              <TextField
                id="document_number"
                inputProps={{ maxLength: 12 }}
                onChange={formChange}
                value={props.userData.document_number}
                helperText="RUT"
                fullWidth
              />
            )}
          {props.userData.document_type === "passport" && (
            <TextField
              id="document_number"
              inputProps={{ minLength: 4 }}
              onChange={formChange}
              value={props.userData.document_number}
              helperText="Pasaporte"
              fullWidth
            />
          )}
          {props.prefill && (
            <SButton
              variant="outlined"
              color="primary"
              disabled={fetchingPatient}
              onClick={prefillHandler}
            >
              Rellenar
            </SButton>
          )}
        </Row>
      )}
      {props.userData.country === "mx" && (
        <>
          <Row>
            <FormControl fullWidth>
              <RadioGroup
                row={true}
                value={
                  ["ci", "generic_id"].includes(props.userData.document_type)
                    ? "generic_id"
                    : "passport"
                }
                onChange={formChange}
                defaultValue="generic_id"
              >
                <FormControlLabel
                  value="generic_id"
                  control={<Radio id="document_type" />}
                  label="Generic ID"
                />
                <FormControlLabel
                  value="passport"
                  control={<Radio id="document_type" />}
                  label="Pasaporte"
                />
              </RadioGroup>
            </FormControl>
            {props.userData.document_type === "generic_id" && (
              <div style={{ width: "100%" }}></div>
            )}
            {props.userData.document_type === "passport" && (
              <TextField
                id="document_number"
                inputProps={{ minLength: 4 }}
                onChange={formChange}
                value={props.userData.document_number}
                helperText="Pasaporte"
                fullWidth
              />
            )}
            {props.prefill && (
              <SButton
                variant="outlined"
                color="primary"
                disabled={fetchingPatient}
                onClick={prefillHandler}
              >
                Rellenar
              </SButton>
            )}
          </Row>
        </>
      )}
      <Row>
        <TextField
          id="first_name"
          onChange={formChange}
          value={props.userData.first_name}
          helperText="Primer nombre"
          fullWidth
        />
        <TextField
          id="second_name"
          onChange={formChange}
          value={props.userData.second_name}
          helperText="Segundo nombre"
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          id="last_name"
          onChange={formChange}
          value={props.userData.last_name}
          helperText="Apellido"
          fullWidth
        />
        <TextField
          id="second_last_name"
          onChange={formChange}
          value={props.userData.second_last_name}
          helperText="Segundo apellido"
          fullWidth
        />
      </Row>
      <Row>
        <TextField
          id="email"
          onChange={formChange}
          value={props.userData.email}
          helperText="Correo electrónico"
          fullWidth
          inputProps={{ style: { textTransform: "lowercase" } }}
        />
        <TextField
          id="phone"
          onChange={formChange}
          value={props.userData.phone}
          helperText="Teléfono"
          fullWidth
        />
      </Row>
      <Row>
        <FormControl fullWidth>
          <Select
            onChange={genderChange}
            value={props.userData.gender}
            fullWidth
          >
            <MenuItem value="male">Hombre</MenuItem>
            <MenuItem value="female">Mujer</MenuItem>
            <MenuItem value="other">Otro</MenuItem>
          </Select>
          <FormHelperText>Sexo</FormHelperText>
        </FormControl>
        <TextField
          fullWidth
          id="date_of_birth"
          helperText="Fecha de nacimiento"
          type="date"
          onChange={formChange}
          value={props.userData.date_of_birth}
        />
      </Row>
      {props.insurances && (
        <Row>
          <FormControl fullWidth>
            <Select
              onChange={insuranceChange}
              value={props.userData.insurance}
              fullWidth
            >
              {props.insurances.map((insurance) => (
                <MenuItem value={insurance}>{insurance}</MenuItem>
              ))}
            </Select>
            <FormHelperText>Previsión</FormHelperText>
          </FormControl>
        </Row>
      )}
    </>
  );
};

export default UserInput;
