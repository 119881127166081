import api from "../api";
import { AxiosResponse } from "axios";

class InsuranceService {
  fetchInsurers = (): Promise<AxiosResponse<any>> => {
    return api.get(
      `/insurance/insurers/`
    );
  };
}

const insuranceService = new InsuranceService();

export default insuranceService;
