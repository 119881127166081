import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";
import appointmentService from "../../../utils/api/v1/appointmentService";
import insuranceService from "../../../utils/api/v1/insuranceService";
import patientService from "../../../utils/api/v1/patientService";

// typings
import { User } from "../../../utils/interfaces/User";

// components
import PrivateRoute from "../../../components/Authentication/PrivateRoute";
import LoadingError from "../../../components/Loaders/LoadingError";
import UserInput from "../../../components/Users/UserInput";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import Swal from "sweetalert2";
import { Patient } from "../../../utils/interfaces/AppointmentPatient";

const EditContainer = styled(Paper)`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  .MuiButtonBase-root {
    float: right;
    margin: 20px 10px;
  }
`;

interface Props {
  id: string;
}

const editUser = ({ id }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [insurances, setInsurances] = useState<string[]>([]);
  const [patientData, setPatientData] = useState<Patient>({
    id: "",
    email: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    phone: "",
    date_of_birth: "",
    gender: "male",
    document_type: "ci",
    document_number: "",
    insurance: "",
  });

  const fetchUserData = async (): Promise<void> => {
    setLoading(true);
    setError("");
    try {
      const patientRequest = await appointmentService.fetchPatient(id);
      const data: Patient = patientRequest.data.data;
      data.date_of_birth = data.date_of_birth?.substring(0, 10);
      setPatientData(data);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(err.response.data)}`,
        confirmButtonText: "Continuar",
      })
    }
    setLoading(false);
  };


  const fetchInsurances = async (): Promise<void> => {
    setLoading(true);
    setError("");
    try {
      const insurancesRequest = await insuranceService.fetchInsurers();
      const data: string[] = insurancesRequest.data.data;
      setInsurances(data);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(err.response.data)}`,
        confirmButtonText: "Continuar",
      })
    }
    setLoading(false);
  }


  const changePatientData = async (patientData: Patient, error: String): Promise<void> => {
    const params = new URLSearchParams(location.search);
    const appointmentId = params.get("appointment") ?? "";
    const appointmentPatientId = params.get("appointment_patient") ?? "";

    const newPatientId = error.split(", ")[2].split(")")[0];
    try {
      const newPatient = await patientService.detailsByIdentifier(newPatientId);
      await appointmentService.addPatient(
        appointmentId,
        newPatient.data.data.id
      );
      await patientService.deactivate(appointmentPatientId);
      navigate(`/appointment/${appointmentId}`);
    } catch (err) {
      await Swal.fire({
        icon: "error",
        title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
        text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(
          err.response.data
        )}`,
        confirmButtonText: "Continuar",
      });
    }
    setLoading(false);
    fetchUserData();
  }

  const parseData = (patientData: Patient): Patient => {
    const data = patientData;
    const birthDate: Date = new Date(data.date_of_birth);
    birthDate.setHours(12);
    birthDate.setDate(birthDate.getDate() + 1);
    data.date_of_birth = birthDate.toISOString();
    return data;
  }

  const submitButton = async (): Promise<void> => {
    setLoading(true);
    try {
      const data = parseData(patientData);
      const request = await appointmentService.updatePatient(id, data);
      if (request.data.msg === "Patient updated") {
        await Swal.fire({
          icon: "success",
          title: "Paciente modificado exitosamente",
          confirmButtonText: "Continuar",
          didClose: () => {
            const params = new URLSearchParams(location.search);
            const return_url = params.get("return_url");
            if (return_url && return_url != '') {
              navigate(return_url)
            } else {
              navigate(`/patients`)
            }
          }
        });
      }
    } catch (err) {
      // Check duplicate user
      if (err.response.data.err.includes("duplicate key value violates unique constraint \"unique_document\"")) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "El paciente que intentas generar ya existe",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Volver a editar",
          confirmButtonText: "Vincular el paciente correcto"
        }).then((result) => {
          if (result.isConfirmed) {
            // vincular paciente correcto
            changePatientData(patientData, err.response.data.err);
          } else {
            fetchUserData();
          }
        });
      } else {
        await Swal.fire({
          icon: "error",
          title: `Lo sentimos, ha ocurrido un error. (${err.response.status})`,
          text: `Si el error persiste, por favor comunícate con el equipo de desarrollo y muestrales el siguiente error: ${JSON.stringify(err.response.data)}`,
          confirmButtonText: "Continuar",
        });
        fetchUserData();
      }
    }
  }

  useEffect((): void => {
    fetchUserData();
    fetchInsurances();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError loading={loading} error={error} />
      {!loading && (
        <EditContainer variant="outlined">
          <h1>Editar paciente</h1>
          <UserInput userData={patientData} setUserData={setPatientData} insurances={insurances} />
          <ButtonContainer>
            <Link to={`/patients/${patientData.id}`}>
              <Button variant="text" color="primary">
                Volver
              </Button>
            </Link>
            <Button variant="contained" onClick={submitButton} color="primary">
              Guardar
            </Button>
          </ButtonContainer>
        </EditContainer>
      )}
    </PrivateRoute>
  );
};

export default editUser;
